<template>
  <div id="hardware-categories" class="hardware-categories-container">

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Hardware'">
      <template v-slot:right-end>
        <div class="vx-row">
          <div class="p-3"></div>
          <button
            @click="openAddProductPage"
            class="text-white flex items-center space-x-2 bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            type="button">
            <span class="text-2xl">+</span><span>{{ $t('AddProduct') }}</span>
          </button>

        </div>
      </template>
    </breadcrumb-base>

    <!--    Empty List State -->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="products.length === 0 && !errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <!--          <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
          <!--               class="mx-auto mb-4 max-w-full">-->
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div class="flex flex-wrap gap-x-6 gap-y-6">
        <a
          v-for="(product, index) of products"
          @click="openCategoryProductsPage(product)"
          class="flex cursor-pointer flex-col space-y-2 justify-between p-4 min-w-xs max-w-xs bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <img :src="product.image" alt="product"
               class="w-full">
          <div class="flex flex-row justify-between space-x-2">
            <h5 class="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {{ product.name }}
            </h5>
            <h5
              class="text-2xl text-success font-bold tracking-tight text-gray-900 dark:text-white">
              {{ product.price | germanNumberFormat }}
            </h5>
          </div>
          <p class="tracking-tight text-gray-900 dark:text-white">
            {{ product.description }}
          </p>
          <div class="flex flex-row">
            <vs-spacer></vs-spacer>
                        <button type="button"
                                @click="editProduct(product)"
                                class="text-white bg-primary font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                               fill="currentColor">
                            <path
                              d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"/>
                          </svg>
                        </button>
            <button type="button"
                    @click="deleteProductConfirm(product)"
                    class="text-white bg-danger font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                   fill="currentColor">
                <path fill-rule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clip-rule="evenodd"/>
              </svg>
            </button>
          </div>
        </a>

      </div>
    </transition>

    <vx-modal @close="closeAddProductPage()" v-if="showAddProductModal"
              component-class="modal-container-fit">
      <template slot="body">
        <div>
          <div class=" mb-4"
               style="font-size: 24px;font-family: Montserrat, Helvetica, Arial, sans-serif;font-weight: 500;line-height: 1.2;color: #2c2c2c;">

            <h1 class=" " style="text-align: center;">{{ $t('AddNewProduct') }}</h1>
          </div>
          <div class="py-4" style="text-align: center;">
            <div class="text-left">
              <label for="first_name"
                     class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                  $t('ProductName')
                }}</label>
              <input type="text" id="product_name"
                     v-model="productName"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     placeholder="IPhone XS" required>
            </div>
          </div>
          <div class="py-4" style="text-align: center;">
            <div class="text-left">
              <label for="first_name"
                     class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                  $t('ProductDescription')
                }}</label>
              <input type="text" id="product_description"
                     v-model="productDescription"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     placeholder="..." required>
            </div>
          </div>
          <div class="py-4" style="text-align: center;">
            <div class="text-left">
              <label for="first_name"
                     class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                  $t('ProductPrice')
                }}</label>
              <input type="text" id="product_price"
                     v-model="productPrice"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     placeholder="" required>
            </div>
          </div>
          <div class="py-4" style="text-align: center;">
            <div class="text-left">
              <label class="block mb-2 text-sm font-medium text-gray-900"
                     for="file_input">Upload file</label>
              <input
                class="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="file_input"
                name="myImage" accept="image/*" ref="fileUpload"
                @change="selectedNewFile"
                type="file">
            </div>
          </div>

          <div class="py-4 flex justify-center relative items-center" style="text-align: center;" v-if="productImageSrc !== ''" >
            <div class="p-image-holder c-center">
              <img class="p-image max-w-[250px]" :src="productImageSrc" alt="product image">
            </div>
          </div>

          <div class="flex flex-wrap justify-center mb-3 py-4">
            <vs-button class="mx-3" color="danger" type="border" @click="closeAddProductPage()">
              {{ $t('Cancel') }}
            </vs-button>
            <vs-button class="mx-3" v-if="selectedEditProduct" @click="updateProduct">{{ $t('UpdateProduct') }}</vs-button>
            <vs-button class="mx-3" v-else  @click="createNewProduct">{{ $t('AddProduct') }}</vs-button>
          </div>
        </div>
      </template>
    </vx-modal>

  </div>
</template>

<script>
import VxModal from "@/layouts/components/custom/VxModal";

export default {
  name: "AdminHardwareCatProducts",
  components: {VxModal},
  props: {
    categoryId: {
      type: String | Number,
    },
  },
  data() {
    return {
      errorFetching: false,
      showAddProductModal: false,
      productName: "",
      productDescription: "",
      productPrice: "",
      productImage: null,
      selectedEditProduct: null,
      productImageSrc: "",
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Hardware', i18n: 'Hardware', active: true},
      ],
    }
  },
  computed: {
    products() {
      return this.$store.state.hardware.products;
    },
  },
  methods: {
    openAddProductPage() {
      this.showAddProductModal = true
    },
    closeAddProductPage() {
      this.productImageSrc = '';
      this.productName = '';
      this.productDescription = '';
      this.productPrice = ''
      this.selectedEditProduct = null;
      this.showAddProductModal = false
    },
    openCategoryProductsPage(cat) {
      // this.$router.push({name: 'hardware-category', params: {id: cat.id}})
    },
    async selectedNewFile(e) {
      this.productImage = this.$refs.fileUpload.files[0]
      const readURL = (file) => new Promise((res, rej) => {
        const reader = new FileReader()
        reader.onload = (e) => res(e.target.result)
        reader.onerror = (e) => rej(e)
        reader.readAsDataURL(file)
      })

      this.productImageSrc = await readURL(this.productImage)
    },
    createNewProduct() {
      if (!this.productName) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: this.$t('PleaseEnterProductName'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return;
      }
      if (!this.productPrice || isNaN(this.productPrice)) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: this.$t('PleaseEnterValidPrice'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        return;
      }
      this.$vs.loading()
      let data = {
        category_id: this.categoryId,
        name: this.productName,
        description: this.productDescription,
        price: this.productPrice,
        image: this.productImage,
      }
      this.closeAddProductPage();
      this.$store.dispatch('hardware/createNewHardwareProduct', data)
        .then(data => {
          this.$vs.loading.close()
          this.fetchCategoryProducts();
        })
        .catch((error) => {
          this.categoryName = '';
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchCategoryProducts() {
      this.$vs.loading()
      this.$store.dispatch('hardware/fetchHardwareCategoryProducts', {
        category_id: this.categoryId,
      })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    editProduct(prod) {
      this.productImageSrc = prod.image;
      this.productName = prod.name;
      this.productDescription = prod.description;
      this.productPrice = prod.price;
      this.selectedEditProduct = prod.id;
      this.openAddProductPage();
    },
    deleteProductConfirm(prod) {
      if (confirm(`Are you sure you want to delete ${prod.name}`) == true) {
        this.deleteProduct(prod.id)
      }
    },
    updateProduct() {
      if (!this.productName) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: this.$t('PleaseEnterProductName'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return;
      }
      if (!this.productPrice || isNaN(this.productPrice)) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: this.$t('PleaseEnterValidPrice'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        return;
      }
      this.$vs.loading()
      let data = {
        category_id: this.categoryId,
        name: this.productName,
        description: this.productDescription,
        price: this.productPrice,
        id: this.selectedEditProduct,
      }
      this.closeAddProductPage();
      if(this.productImage) {
        data.image = this.productImage
      }
      this.$store.dispatch('hardware/updateHardwareProduct', data)
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.fetchCategoryProducts();
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    deleteProduct(prodId) {
      this.$vs.loading();
      this.$store.dispatch('hardware/deleteHardwareProduct', {id: prodId})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.fetchCategoryProducts();
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    }
  },
  mounted() {
    this.fetchCategoryProducts();
  },
}
</script>

<style scoped>

</style>
