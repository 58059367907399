import { render, staticRenderFns } from "./AdminHardwareCatProducts.vue?vue&type=template&id=5766c085&scoped=true&"
import script from "./AdminHardwareCatProducts.vue?vue&type=script&lang=js&"
export * from "./AdminHardwareCatProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5766c085",
  null
  
)

export default component.exports